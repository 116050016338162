<template>
  <div class="dashboard">
    <v-container center>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="item in dashboardItems"
          :key="item.title"
        >
          <v-card :color="item.color" @click="goTo(item)">
            <v-row>
              <v-col class="text-center">
                <v-icon color="white" size="48">{{ item.icon }}</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <span :class="item.textclass">{{ item.title }}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>
  </div>
</template>

<script>
import SnackbarMensajes from "@/components/SnackbarMensajes.vue";
import menuitems from "@/menuinventario.js";

const ITEMS = menuitems.items;

export default {
  name: "Dashboard",
  components: { SnackbarMensajes },
  data() {
    return {
      sb: {
        mostrar: false,
        mensaje: "",
        color: "success",
        timeout: 3000,
        multiline: false,
      },
    };
  },
  computed: {
    dashboardItems() {
      let dashitems = [];
      ITEMS.forEach((element) => {
        if (element.dash) dashitems.push(element);
      });
      return dashitems;
    },
    noleidoscount() {
      return this.$store.getters.noleidoscount;
    },
  },
  methods: {
    goTo(item) {
      if (this.$store.getters.hasPermission(item.permiso))
        this.$router.push(item.link);
      else {
        this.showMensaje(
          "No tiene permiso para acceder al módulo",
          "error",
          true
        );
      }
    },
    showMensaje(mensaje, color, multiline) {
      this.sb.mensaje = mensaje;
      this.sb.multiline = multiline;
      this.sb.color = color;
      this.sb.mostrar = true;
    },
  },
  created() {},
};
</script>
